import React, { useState } from "react"
import { Link } from "gatsby"

import { Tabs } from 'antd';

import Layout from "../components/layout"
import SEO from "../components/seo"

import ContactForm from "../components/ContactForm"


import SimpleReactLightbox, { SRLWrapper } from "simple-react-lightbox"

//Images

import { Button, Input, Modal, Tag } from "antd"

import Remontroirs_029 from "../images/images/remontroirs_montres_automatiques/Origin7-500x500.jpg"
import Remontroirs_001 from "../images/images/remontroirs_montres_automatiques/remontroirs_001.jpg"
import Remontroirs_002 from "../images/images/remontroirs_montres_automatiques/remontroirs_002.jpg"
import Remontroirs_003 from "../images/images/remontroirs_montres_automatiques/remontroirs_003.jpg"
import Remontroirs_004 from "../images/images/remontroirs_montres_automatiques/remontroirs_004.jpg"
import Remontroirs_005 from "../images/images/remontroirs_montres_automatiques/rotore_one_sport_cyclamen_resized.jpg"
import Remontroirs_006 from "../images/images/remontroirs_montres_automatiques/rotore_one_sport_lilac_resized.jpg"
import Remontroirs_007 from "../images/images/remontroirs_montres_automatiques/rotor-one_blue_batman.jpg"
import Remontroirs_008 from "../images/images/remontroirs_montres_automatiques/rotor-one_red_pepsi.jpg"
import Remontroirs_009 from "../images/images/remontroirs_montres_automatiques/191101_scatola_boitenoire_ouvert.jpg"
import Remontroirs_010 from "../images/images/remontroirs_montres_automatiques/191101_scatola_boitegrise_ouvert.jpg"
import Remontroirs_011 from "../images/images/remontroirs_montres_automatiques/valigetta_4_choco.jpg"
import Remontroirs_012 from "../images/images/remontroirs_montres_automatiques/valigetta_4_bicolor.jpg"
import Remontroirs_013 from "../images/images/remontroirs_montres_automatiques/viaggio_1_choco.jpg"
import Remontroirs_014 from "../images/images/remontroirs_montres_automatiques/viaggio_1_black.jpg"
import Remontroirs_015 from "../images/images/remontroirs_montres_automatiques/viaggio_1_chestnut.jpg"
import Remontroirs_016 from "../images/images/remontroirs_montres_automatiques/viaggio_6_black.jpg"
import Remontroirs_017 from "../images/images/remontroirs_montres_automatiques/tesoro_chestnut_2_1_3.jpg"
import Remontroirs_018 from "../images/images/remontroirs_montres_automatiques/7rt_black_2.jpg"
import Remontroirs_019 from "../images/images/remontroirs_montres_automatiques/7rt_chestnut_2.jpg"
import Remontroirs_020 from "../images/images/remontroirs_montres_automatiques/valigetta_8_chestnut.jpg"
import Remontroirs_021 from "../images/images/remontroirs_montres_automatiques/valigetta_8_choco.jpg"
import Remontroirs_022 from "../images/images/remontroirs_montres_automatiques/valigetta_8_handle_black.jpg"
import Remontroirs_023 from "../images/images/remontroirs_montres_automatiques/valigetta_8_black.jpg"
import Remontroirs_024 from "../images/images/remontroirs_montres_automatiques/valigetta_16_chestnut.jpg"
import Remontroirs_025 from "../images/images/remontroirs_montres_automatiques/191101_scatola_pochegrise_montres.jpg"
import Remontroirs_026 from "../images/images/remontroirs_montres_automatiques/191101_scatola_pochenoire_montres.jpg"
import Remontroirs_027 from "../images/images/remontroirs_montres_automatiques/cornice_3_black.jpg"
import Remontroirs_028 from "../images/images/remontroirs_montres_automatiques/timesafe_xxl_open_resized.jpg"

const options = {
  overlayColor: "rgba(44,47,76,0.9)",
  captionStyle: {
    captionColor: "#857e74",
    captionFontFamily: "Raleway, sans-serif",
    captionFontSize: "22px",
    captionFontWeight: "300",
    captionFontStyle: "capitalize",
  },
  buttonsStyle: {
    buttonsBackgroundColor: "#857e74",
    buttonsIconColor: "rgba(176,158,111,0.8)",
  },
  autoplaySpeed: 1500,
  transitionSpeed: 0,
  showCaption: false,
  showThumbnails: false,
}

const Horlogeries_yvan_monet = () => {

  const { TabPane } = Tabs;

  //Hooks
  const [dataContact, setDataContact] = useState(false)

  //Modal
  const [showModalContact, setShowModalContact] = useState(false)

  const showModalContactForm = (value_i) => {
    setShowModalContact(true)
    setDataContact(value_i)
  }

  const handleContactFormOk = e => {
    setShowModalContact(false)
  }

  const handleContactFormCancel = e => {
    setShowModalContact(false)
  }

  return (
    <>
      <Layout>
        <SEO title="Scatola del Tempo"/>

        <div className="slideshow">
          <div className="usp">
            <div className="usp-txt-header">Scatola del Tempo</div>
          </div>
        </div>
        <div className="border-gold-bottom"></div>


        <section>
          <div className="section-flex-01" style={{ margin: "0px" }}>

            <h2 className="h2-mobile"  style={{textAlign: 'center'}}>Scatola del Tempo</h2>

            <div style={{ display: "flex", flexDirection: "column" }}>
              <div className="watch-intro" style={{ marginBottom: 0 }}>
                <img className="img" style={{ margin: "0px", borderRadius: "12px" }} src={Remontroirs_029} alt="Scatola del Tempo"/>
                <p className="p-2 p-font">
                  Depuis 1989, au début de sa nouvelle activité, Sandro Colarieti décide de faire évoluer les boîtiers qu'il crée pour stocker ses montres en produisant une petite série de remontoirs pour lui et ses amis. Son objectif: simuler les mouvements naturels du poignet pour maintenir ses montres et les garder à l'heure.
                </p>
              </div>
              <p className="p-2 p-font">
                Grand collectionneur de montres Patek Philippe, il présente son invention à la célèbre maison horlogère en 1990, qui décide, après un an de tests, de commander 500 remontoirs. Grâce à cette collaboration, Scatola s'est rapidement fait connaître des collectionneurs du monde entier. Le bobineur est né et approuvé! L'engouement des collectionneurs ne cesse de croître et leurs attentes sont de plus en plus exigeantes en termes de personnalisation et de capacité. Scatola del Tempo aime les défis! La gamme est élargie avec des articles sur mesure capables de contenir jusqu'à des centaines de montres.
              </p>
              <SimpleReactLightbox>
                <SRLWrapper options={options}>
                  <div id="gallery-with-links" className="container content content-gallery">
                    <div className="img-container col-image-with-link">
                      <a href={Remontroirs_001} data-attribute="SRL"><img className="img-gallery" src={Remontroirs_001}
                                                                          alt="Scatola del Tempo"/></a>
                    </div>
                    <div className="img-container col-image-with-link">
                      <a href={Remontroirs_002} data-attribute="SRL"><img className="img-gallery" src={Remontroirs_002}
                                                                          alt="Scatola del Tempo"/></a>
                    </div>
                    <div className="img-container col-image-with-link">
                      <a href={Remontroirs_003} data-attribute="SRL"><img className="img-gallery" src={Remontroirs_003}
                                                                          alt="Scatola del Tempo"/></a>
                    </div>
                    <div className="img-container col-image-with-link">
                      <a href={Remontroirs_004} data-attribute="SRL"><img className="img-gallery" src={Remontroirs_004}
                                                                          alt="Scatola del Tempo"/></a>
                    </div>
                    <div className="img-container col-image-with-link">
                      <a href={Remontroirs_005} data-attribute="SRL"><img className="img-gallery" src={Remontroirs_005}
                                                                          alt="Scatola del Tempo"/></a>
                    </div>
                    <div className="img-container col-image-with-link">
                      <a href={Remontroirs_006} data-attribute="SRL"><img className="img-gallery" src={Remontroirs_006}
                                                                          alt="Scatola del Tempo"/></a>
                    </div>
                    <div className="img-container col-image-with-link">
                      <a href={Remontroirs_007} data-attribute="SRL"><img className="img-gallery" src={Remontroirs_007}
                                                                          alt="Scatola del Tempo"/></a>
                    </div>
                    <div className="img-container col-image-with-link">
                      <a href={Remontroirs_008} data-attribute="SRL"><img className="img-gallery" src={Remontroirs_008}
                                                                          alt="Scatola del Tempo"/></a>
                    </div>
                    <div className="img-container col-image-with-link">
                      <a href={Remontroirs_009} data-attribute="SRL"><img className="img-gallery" src={Remontroirs_009}
                                                                          alt="Scatola del Tempo"/></a>
                    </div>
                    <div className="img-container col-image-with-link">
                      <a href={Remontroirs_010} data-attribute="SRL"><img className="img-gallery" src={Remontroirs_010}
                                                                          alt="Scatola del Tempo"/></a>
                    </div>
                    <div className="img-container col-image-with-link">
                      <a href={Remontroirs_011} data-attribute="SRL"><img className="img-gallery" src={Remontroirs_011}
                                                                          alt="Scatola del Tempo"/></a>
                    </div>
                    <div className="img-container col-image-with-link">
                      <a href={Remontroirs_012} data-attribute="SRL"><img className="img-gallery" src={Remontroirs_012}
                                                                          alt="Scatola del Tempo"/></a>
                    </div>
                    <div className="img-container col-image-with-link">
                      <a href={Remontroirs_013} data-attribute="SRL"><img className="img-gallery" src={Remontroirs_013}
                                                                          alt="Scatola del Tempo"/></a>
                    </div>
                    <div className="img-container col-image-with-link">
                      <a href={Remontroirs_014} data-attribute="SRL"><img className="img-gallery" src={Remontroirs_014}
                                                                          alt="Scatola del Tempo"/></a>
                    </div>
                    <div className="img-container col-image-with-link">
                      <a href={Remontroirs_015} data-attribute="SRL"><img className="img-gallery" src={Remontroirs_015}
                                                                          alt="Scatola del Tempo"/></a>
                    </div>
                    <div className="img-container col-image-with-link">
                      <a href={Remontroirs_016} data-attribute="SRL"><img className="img-gallery" src={Remontroirs_016}
                                                                          alt="Scatola del Tempo"/></a>
                    </div>
                    <div className="img-container col-image-with-link">
                      <a href={Remontroirs_017} data-attribute="SRL"><img className="img-gallery" src={Remontroirs_017}
                                                                          alt="Scatola del Tempo"/></a>
                    </div>
                    <div className="img-container col-image-with-link">
                      <a href={Remontroirs_018} data-attribute="SRL"><img className="img-gallery" src={Remontroirs_018}
                                                                          alt="Scatola del Tempo"/></a>
                    </div>
                    <div className="img-container col-image-with-link">
                      <a href={Remontroirs_019} data-attribute="SRL"><img className="img-gallery" src={Remontroirs_019}
                                                                          alt="Scatola del Tempo"/></a>
                    </div>
                    <div className="img-container col-image-with-link">
                      <a href={Remontroirs_020} data-attribute="SRL"><img className="img-gallery" src={Remontroirs_020}
                                                                          alt="Scatola del Tempo"/></a>
                    </div>
                    <div className="img-container col-image-with-link">
                      <a href={Remontroirs_021} data-attribute="SRL"><img className="img-gallery" src={Remontroirs_021}
                                                                          alt="Scatola del Tempo"/></a>
                    </div>
                    <div className="img-container col-image-with-link">
                      <a href={Remontroirs_022} data-attribute="SRL"><img className="img-gallery" src={Remontroirs_022}
                                                                          alt="Scatola del Tempo"/></a>
                    </div>
                    <div className="img-container col-image-with-link">
                      <a href={Remontroirs_023} data-attribute="SRL"><img className="img-gallery" src={Remontroirs_023}
                                                                          alt="Scatola del Tempo"/></a>
                    </div>
                    <div className="img-container col-image-with-link">
                      <a href={Remontroirs_024} data-attribute="SRL"><img className="img-gallery" src={Remontroirs_024}
                                                                          alt="Scatola del Tempo"/></a>
                    </div>
                    <div className="img-container col-image-with-link">
                      <a href={Remontroirs_025} data-attribute="SRL"><img className="img-gallery" src={Remontroirs_025}
                                                                          alt="Scatola del Tempo"/></a>
                    </div>
                    <div className="img-container col-image-with-link">
                      <a href={Remontroirs_026} data-attribute="SRL"><img className="img-gallery" src={Remontroirs_026}
                                                                          alt="Scatola del Tempo"/></a>
                    </div>
                    <div className="img-container col-image-with-link">
                      <a href={Remontroirs_027} data-attribute="SRL"><img className="img-gallery" src={Remontroirs_027}
                                                                          alt="Scatola del Tempo"/></a>
                    </div>
                    <div className="img-container col-image-with-link">
                      <a href={Remontroirs_028} data-attribute="SRL"><img className="img-gallery" src={Remontroirs_028}
                                                                          alt="Scatola del Tempo"/></a>
                    </div>
                  </div>
                </SRLWrapper>
              </SimpleReactLightbox>
              <div style={{display: 'flex', justifyContent: 'center'}}><Button className="button-gold-box btn-horlogeries" onClick={ () => showModalContactForm('Scatola del Tempo') }>Nous contacter</Button></div>
            </div>

          </div>
        </section>

        <Modal title="Formulaire de contact"
        style={{minWidth: '40vw', height: '80vh'}}
          visible={ showModalContact }
          onOk={ handleContactFormOk }
          onCancel={ handleContactFormCancel }
          okButtonProps={{ style: { display: 'none' } }}
        >

          <ContactForm valu={ dataContact } />

        </Modal>

      </Layout>
    </>
  )
}

export default Horlogeries_yvan_monet
